@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.radioButton {
  @include mixins.radioButton();

  margin-bottom: functions.rem(12);
}

.description {
  opacity: .78;
  line-height: functions.rem(18);
}

.length {
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold)
}