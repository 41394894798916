@use 'styles/variables.scss';
@use 'styles/mixins.scss';
@use 'styles/functions.scss';

.columns {
  display: flex;
  flex-wrap: wrap;

  @include mixins.tabletSmall {
    flex-direction: column;
  }
}
.middle {
  flex: 1;
  width: 100%;
  order: 2;
  display: flex;
  // align-content: center;
  // justify-content: center;
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-size: functions.rem(11);
  flex-basis: 100%;

  margin-top: functions.rem(16);

  > div {
    width: auto
  }

  @include mixins.tablet {
    order: unset;
    margin-bottom: functions.rem(16);
  }

  @include mixins.tabletSmall {
    margin-top: functions.rem(0);
  }

  label {
    font-size: functions.rem(11);
  }
}
.column {
  flex: 1;
  margin-right: functions.rem(24);
  padding: functions.rem(20);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: functions.rem(variables.$border-radius);

  @include mixins.tablet {
    margin-right: functions.rem(19);
    padding-left: functions.rem(16);
    padding-right: functions.rem(16);
  }

  @include mixins.tabletSmall {
    margin-bottom: functions.rem(22);
    margin-right: 0;
    padding: functions.rem(20) functions.rem(18) functions.rem(18);
  }

  &:last-child {
    margin-right: 0;

    @include mixins.tabletSmall {
      margin-bottom: 0;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: functions.rem(17);
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold);
  font-size: functions.rem(11);
  line-height: functions.rem(13);
}

.field {
  margin-bottom: functions.rem(14);
  padding: 0;
}
