@use 'src/styles/variables.scss';
@use 'src/styles/functions.scss';

.rotation-component {
  display: flex;
  justify-content: center;
  padding: functions.rem(18);

  .rotation {
    position: relative;
  }

  .topFitting {
    position: absolute;
    top: functions.rem(21.5);
    left: 50%;
    width: functions.rem(45);
    height: functions.rem(114);
    transform: translateX(-50%);

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .bottomFitting {
    position: absolute;
    top: functions.rem(38);
    left: 50%;
    width: functions.rem(32);
    height: functions.rem(91);
    transform: translateX(-50%);
    transform-origin: center functions.rem(75);

    img {
      bottom: 5px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .cursor {
    position: absolute;
    content: '';
    left: 50%;
    top: functions.rem(-39);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: functions.rem(32);
    height: functions.rem(32);
    padding: 0;
    border: none;
    background: var(--color-white, variables.$white);
    box-shadow: 0 functions.rem(4) functions.rem(11) rgba(0, 61, 100, 0.13);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow .2s ease-out;

    &:hover {
      box-shadow: 0 functions.rem(4) functions.rem(11) rgba(0, 61, 100, 0.3);
    }

    &:active {
      border: functions.rem(1) solid variables.$primary;
      box-shadow: 0 functions.rem(4) functions.rem(11) rgba(0, 61, 100, 0.3);
    }

    &::after {
      content: '';
      width: functions.rem(24);
      height: functions.rem(24);
      background: var(--color-primary, variables.$primary) url('/assets/cursor.svg') center no-repeat;
      border-radius: 50%;
    }
  }

  .indicator {
    display: block;
    width: functions.rem(228);
    height: functions.rem(228);
    transform: rotate(-90deg);
  }

  .indicatorTrack,
  .indicatorProgress {
    fill: transparent;
    stroke-width: functions.rem(14);
  }

  .indicatorTrack {
    stroke: var(--color-indicatorTrack, variables.$indicatorTrack);
  }

  .indicatorProgress {
    stroke: var(--color-indicatorProgress, variables.$indicatorProgress);
    transition: stroke-dashoffset 0.3s ease-out;
  }

  .measurement {
    position: absolute;
    font-size: functions.rem(13);
    font-weight: var(--font--weight-bold, variables.$font-weight-bold);
    color: #1F1F1F;

    span {
      position: absolute;
      right: functions.rem(-8);
      top: functions.rem(-2);
      opacity: .58;
    }
  }

  .measurementSm {
    font-size: functions.rem(9);
    font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold);
    line-height: functions.rem(11);
  }

  .measurement0 {
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-100%);
  }

  .measurement45 {
    right: functions.rem(20);
    top: functions.rem(24);
  }

  .measurement90 {
    right: 0;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
  }

  .measurement135 {
    right: functions.rem(20);
    bottom: functions.rem(24);
  }

  .measurement180 {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(100%);
  }

  .measurement225 {
    left: functions.rem(11);
    bottom: functions.rem(24);
  }

  .measurement270 {
    left: 0;
    top: 50%;
    transform: translateX(-100%) translateY(-50%);
    margin-left: functions.rem(-8);
  }

  .measurement315 {
    left: functions.rem(11);
    top: functions.rem(24);
  }
}
