@use './variables.scss';
@use 'src/styles/functions.scss';

// media
@mixin laptop {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin tablet  {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin tabletSmall  {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin heightSmall  {
  @media (max-height: 900px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 576px) {
    @content;
  }
}

// typography
@mixin h1() {
  font-weight: var(--font--weight-bold, variables.$font-weight-bold);
  font-size: functions.rem(24);
  line-height: functions.rem(28);
}

@mixin h2() {
  font-weight: var(--font--weight-bold, variables.$font-weight-bold);
  font-size: functions.rem(14);
  line-height: functions.rem(16);
}

@mixin h3() {
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold);
  font-size: functions.rem(12);
  line-height: functions.rem(15);
}

@mixin h4() {
  font-weight: var(--font--weight-bold, variables.$font-weight-bold);
  font-size: functions.rem(11);
  line-height: functions.rem(13);
}

@mixin h5() {
  font-weight: var(--font--weight-regular, variables.$font-weight-regular);
  font-size: functions.rem(11);
  line-height: functions.rem(13);
}

@mixin hovered($bcg: var(--color-primary-hover, variables.$primary-hover), $shadow-color: var(--color-primary-hover-shadow, variables.$primary-hover-shadow)) {
  background-color: $bcg;
  box-shadow: 0 functions.rem(4) functions.rem(16) $shadow-color;
}

// forms
@mixin label() {
  position: absolute;
  z-index: var(--z-index-label, variables.$zIndex-label);
  left: functions.rem(14);
  top: 0;
  padding: 0 functions.rem(6);
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-size: functions.rem(12);
  font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold);
  line-height: functions.rem(15);
  background-color: var(--color-white, variables.$white);
}

@mixin requiredMark() {
  display: inline-block;
  margin-left: functions.rem(4);
  color: var(--color-red, variables.$red);
}

@mixin radioButton() {
  @include h2();

  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--color-checkbox-text, variables.$checkbox-text);

  &::before {
    content: "";
    flex-shrink: 0;
    display: block;
    margin-right: functions.rem(15);
    width: functions.rem(18);
    height: functions.rem(18);
    border: functions.rem(1) solid var(--color-accent, variables.$accent);
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: functions.rem(5);
    width: functions.rem(8);
    height: functions.rem(8);
    transform: translateY(-50%) scale(0);
    background-color: var(--color-accent, variables.$accent);
    border-radius: 50%;
    transition: transform .2s ease;
  }

  [class*="input"]:checked + &::after {
    transform: translateY(-50%) scale(1);
  }

  [class*="input"]:checked + div &::after {
    transform: translateY(-50%) scale(1);
  }
}
