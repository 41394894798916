@use 'variables';
@use 'mixins';
@use 'functions';

.print-body {
  padding: functions.rem(40);
}

.print {
    .logo {
      display: block;
      width: 200px;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .item {
      border-bottom: 1px solid var(--color-border-default, variables.$border-default);
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      min-height: 48px;
      font-size: functions.rem(12);
      line-height: functions.rem(16);
      overflow: hidden;

      a {
        display: flex;
        align-items: center;
        color: var(--color-primary, variables.$primary);
        font-weight: 700;
        font-size: functions.rem(14);
        cursor: pointer;
        text-decoration: underline;

        @media print {
          text-decoration: none;
        }
      }

      div {
        overflow: hidden;
        min-width: 0;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;

        img {
          max-height: 48px;
          overflow: hidden;
          flex-shrink: 0;
          max-width: 100px;
        }
        button {
          display: block;
          background: none;
          border: none;
          cursor: pointer;
          color: var(--color-primary, variables.$primary);
          font-size: 12px;
          font-weight: bold;
        }
      }



      &.alt {
        grid-template-columns: 1fr;
        padding-bottom: 15px;

        a {
          min-height: 48px;
        }

        div {
          justify-content: start;
        }
      }

      div.description {
        grid-column: 1 / span 2;
        justify-content: start;
        padding-top: 5px;
        padding-bottom: 16px;
      }

      @media screen {
        @include mixins.tabletSmall {
          &, &.alt {
            grid-template-columns: 1fr;
            padding-bottom: 10px;

            a {
              margin-top: 9px;
              min-height: 48px;
            }

            div {
              justify-content: start;

              img {
                max-height: 30px;
              }

              flex-wrap: wrap;
            }

            div.description {
              grid-column: 1 / span 1;
            }
          }
        }
      }
    }
}