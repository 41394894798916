@use 'src/styles/variables.scss';
@use 'src/styles/functions.scss';

.loader {
  display: inline-flex;

  &::after {
    content: "";
    font-size: functions.rem(56);
    width: 1em;
    height: 1em;
    border-top: 0.15em solid rgba(0, 117, 191, 0.2);
    border-right: 0.15em solid rgba(0, 117, 191, 0.2);
    border-bottom: 0.15em solid rgba(0, 117, 191, 0.2);
    border-left: 0.15em solid variables.$primary;
    border-radius: 50%;
    transform: translateZ(0);
    animation: load 1.1s infinite linear;
    overflow: hidden;
  }

  &.middle {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &.full {
    &::after {
      font-size: 100vmin;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.formLoaderWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: var(--z-index-loaderBlock, variables.$zIndex-loaderBlock)
}