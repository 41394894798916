@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.quantity {
  display: flex;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid variables.$border-default;

  @include mixins.tabletSmall {
    justify-content: space-between;

    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.tos {
  padding-top: 35px;
}

.desktop {
  display: block;
  @include mixins.tabletSmall {
    display: none;
  }
}

.mobile {
  display: none;
  @include mixins.tabletSmall {
    display: block;
  }
}

.contact {
  border-top: 1px solid variables.$border-default;
  padding-top: 32px;
  margin-top: 35px;
  font-size: functions.rem(12);
  line-height: functions.rem(16);

  label {
    padding-bottom: 12px;
    display: block;
    font-weight: 700;
    font-size: functions.rem(14);
  }

  div {
    opacity: 0.78;
  }

  .buttons {
    padding-top: 24px;
    display: flex;
    gap: 10px;

    a {
      display: flex;
      gap: 5px;
      color: var(--color-primary, variables.$primary);
      font-weight: 500;
      font-size: functions.rem(14);
      cursor: pointer;
      text-decoration: underline;
    }
  }
}



.mailIcon {
  width: 18px;
}

.printIcon {
  width: 16px;
}
