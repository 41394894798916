@use 'src/styles/variables.scss';
@use 'src/styles/functions.scss';

.error {
  margin-top: functions.rem(6);
  font-size: functions.rem(11);
  line-height: functions.rem(13);
  text-align: right;
  color: var(--color-red, variables.$red);

  &.alignLeft {
    text-align: left;
  }
}