@use 'styles/variables.scss';
@use 'styles/mixins.scss';
@use 'styles/functions.scss';

.content {
  button {
    display: block;
    padding: 0;
    background: none;
    border: none;
    padding-top: 8px;
    cursor: pointer;
    color: var(--color-primary, variables.$primary);
    font-size: 12px;
    font-weight: bold;
  }

  div {
    padding-top: 4px;
  }
}
