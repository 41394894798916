@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.card {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: functions.rem(70);
  padding: functions.rem(8);
  background-color: var(--color-white, variables.$white);
  border-radius: functions.rem(6);
  border: 1px solid variables.$main-grey;

  @include mixins.tabletSmall {
    padding: functions.rem(9);
    justify-content: center;
    min-height: functions.rem(100);
  }

  &:not(:last-child) {
    margin-right: -1px;
  }

  &.active {
    border-color: var(--color-primary, variables.$primary);
    position: relative;
    z-index: 1;
  }

  [class*="sticky"] & {
    background-color: var(--color-bgc, variables.$bgc);
  }

  @include mixins.heightSmall {
    padding: functions.rem(8);
    min-height: functions.rem(70);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:first-child {
      border-top-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}