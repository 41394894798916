@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.fulfilled {
  position: relative;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: functions.rem(52);
  margin-right: functions.rem(15);

  @include mixins.tabletSmall {
    width: functions.rem(60);
    margin-right: 0;
  }

  img {
    display: block;
    object-fit: contain;
    max-width: 100%;
  }
}

.title {
  flex-grow: 1;

  @include mixins.h3();

  @include mixins.tabletSmall {
    display: none;
  }
}

.fulfilledIcon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: functions.rem(9);
  width: functions.rem(14);
  height: functions.rem(14);
  background: var(--color-primary, variables.$primary) url('/assets/icons/check-white.svg') center no-repeat;
  background-size: functions.rem(6) functions.rem(5);
  border-radius: 50%;

  @include mixins.tabletSmall {
    display: none;
  }
}
