@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.wrapper {
  min-height: 100vh;
  padding-bottom:  functions.rem(62);
  background-color: var(--color-bgc, variables.$bgc);

  .commonError {
    margin: 20px 0;
    text-align: left;
    font-size: functions.rem(12);
  }
}

.navigation {
  position: sticky;
  top: 0;
  z-index: var(--z-index-navigation, variables.$zIndex-navigation);
  padding-bottom: functions.rem(25);
  background-color: var(--color-bgc, variables.$bgc);

  @include mixins.tabletSmall {
    padding-bottom: functions.rem(20);
  }

  @include mixins.heightSmall {
    padding-bottom: functions.rem(0);
  }

  &.sticky {
    background: var(--color-white, variables.$white);
    box-shadow: 0 functions.rem(4) functions.rem(117) rgba(0, 0, 0, 0.15);
  }
}

.container {
  margin: 0 auto;
  padding: 0 functions.rem(56);
  max-width: functions.rem(1012);

  @include mixins.tabletSmall {
    padding-left: functions.rem(variables.$offset-container);
    padding-right: functions.rem(variables.$offset-container);
  }
}
