:root {
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Inter', sans-serif;
  --font-family-header: var(--font-family-primary);

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  --color-accent: #0075BF;
  --color-text: #000000;
  --color-bgc: #FAFAFA;
  --color-white: #FFFFFF;
  --color-main-grey: #EBEBEB;
  --color-secondary-grey: #EEF0F2;
  --color-dark-grey: #888888;
  --color-red: #E35E5E;

  --color-step-inactive: var(--color-main-grey);
  --color-step-line: #ECECEC;

  --color-scrollbar-track: #d6d6dd;

  --color-primary: var(--color-accent);
  --color-primary-hover: #0269AA;
  --color-primary-hover-shadow: rgba(0, 117, 191, 0.5);
  --color-primary-active: #03619C;
  --color-primary-disabled: rgba(110, 139, 157, .3);
  --color-primary-disabled-font: var(--color-white);

  --color-secondary: var(--color-secondary-grey);
  --color-secondary-hover: #DFE8F0;
  --color-secondary-hover-shadow: var(--color-secondary-grey);
  --color-secondary-active: #CAD7E3;
  --color-secondary-disabled: #F9FAFB;
  --color-secondary-disabled-font: #B2D5EB;

  --color-border-default: var(--color-main-grey);
  --color-border-focus: var(--color-primary);
  --color-border-error: var(--color-red);
  --border-radius: 10;

  --color-checkbox-border: #BEBEBE;
  --color-checkbox-text: #1F1F1F;
  --color-checkbox-select: #2BB483;

  --card-padding-h: 19;
  --card-padding-tablet-h: 15;
  --card-padding-mobile-h: 18;

  --switch-shadow: #0A5382;

  --indicator-track: #F8F7F7;
  --indicator-progress: var(--color-primary);

  --offset-container: 25;

  --z-index-label: 1;
  --z-index-select-menu: 2;
  --z-index-navigation: 3;
  --z-index-loaderBlock: 10;

  --progress-bar-color: var(--color-primary);
  --header-color-code: var(--color-primary);
  --subheader-color-code: var(--color-text);
}