@use 'styles/variables.scss';
@use 'styles/mixins.scss';
@use 'styles/functions.scss';

.loaderBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: var(--z-index-loaderBlock, variables.$zIndex-loaderBlock)
}

.accessories {
  margin-bottom: functions.rem(39);
  padding: 0;
}

.box {
  display: flex;
  margin-bottom: functions.rem(35);
  padding: functions.rem(35) 0;
  border-top: 1px solid variables.$main-grey;
  border-bottom: 1px solid variables.$main-grey;

  @include mixins.tabletSmall {
    flex-direction: column;
  }
}

.testing {
  flex-grow: 1;
  margin-right: functions.rem(33);

  @include mixins.tabletSmall {
    margin-right: 0;
    margin-bottom: functions.rem(25);
  }
}

.checkbox {
  margin-bottom: functions.rem(12);
  padding: 0;
}

.partNumber {
  flex-shrink: 0;
  width: functions.rem(273);

  @include mixins.tabletSmall {
    width: 100%;
  }
}

.partNumberLabel {
  @include mixins.h3();

  display: flex;
  align-items: center;
  margin-bottom: functions.rem(3);
}

.iconTooltip {
  margin-left: functions.rem(6);
}

.input {
  padding: 0;
}

.quantity {
  display: flex;
  align-items: center;

  @include mixins.tabletSmall {
    justify-content: space-between;
  }
}

.quantityLabel {
  @include mixins.h4();

  display: flex;
  align-items: center;
  margin-right: functions.rem(15);

  @include mixins.tabletSmall {
    font-size: functions.rem(14);
    line-height: functions.rem(16);
  }
}